<template>
  <div class="iq-sidebar  sidebar-default ">
    <div class="iq-sidebar-logo d-flex align-items-center justify-content-between">
      <router-link :to="{name: 'product.listproduct'}" class="header-logo">
        <img :src="logo" class="img-fluid rounded-normal" alt="logo">
        <h5 class="logo-title light-logo ml-3">COUPLUS</h5>
      </router-link>
      <div class="iq-menu-bt-sidebar">
        <i class="las la-bars wrapper-menu"></i>
      </div>
    </div>
    <div class="data-scrollbar" data-scroll="1" id="sidebar-scrollbar">
        <nav class="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" class="iq-menu">
                <li :class="checkActive('layout.dashboard1') ? 'active' : ''" v-if="showdashboard">
                    <router-link :to="{name: 'layout.dashboard1'}" class="svg-icon">
                        <svg  class="svg-icon" id="p-dash1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line>
                        </svg>
                        <span class="ml-4">Dashboards</span>
                    </router-link>
                </li>
                <li :class="checkActive(product) ? 'active' : ''" v-if="showproduct">
                    <a v-b-toggle.product class="collapsed" data-toggle="collapse" aria-expanded="false">
                        <svg class="svg-icon" id="p-dash2" width="20" height="20"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle>
                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                        </svg>
                        <span class="ml-4">상품</span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="product" class="iq-submenu" accordion="my-accordion">
                        <li :class="checkActive('product.listproduct') ? 'active' : ''">
                            <router-link :to="{name: 'product.listproduct'}">
                                <i class="las la-minus"></i><span>상품DB</span>
                            </router-link>
                        </li>
                        <!-- <li :class="checkActive('product.regproduct') ? 'active' : ''">
                            <router-link :to="{name: 'product.regproduct'}">
                                <i class="las la-minus"></i><span>상품등록상태</span>
                            </router-link>
                        </li> -->
                        <li :class="checkActive('product.addproduct') ? 'active' : ''">
                            <router-link :to="{name: 'product.addproduct'}">
                                <i class="las la-minus"></i><span>상품DB등록</span>
                            </router-link>
                        </li>

                        <li :class="checkActive('product.regproduct') ? 'active' : ''" v-if="showsales">
                            <router-link :to="{name: 'product.regproduct'}">
                                <i class="las la-minus"></i><span>상품등록(1688)</span>
                            </router-link>
                        </li>


                    </b-collapse>
                </li>
                <li :class="checkActive(categories) ? 'active' : ''" v-if="showpolist">
                    <a v-b-toggle.category class="collapsed" >
                        <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                        </svg>
                        <span class="ml-4">발주</span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="category" class="iq-submenu" accordion="my-accordion">
                            <li :class="checkActive('category.listcategory') ? 'active' : ''">
                                    <router-link :to="{name: 'category.listcategory'}">
                                        <i class="las la-minus"></i><span>발주리스트</span>
                                    </router-link>
                            </li>
                            <!-- <li :class="checkActive('category.addcategory') ? 'active' : ''">
                                    <router-link :to="{ name: 'category.addcategory'}">
                                        <i class="las la-minus"></i><span>Add Category</span>
                                    </router-link>
                            </li> -->
                    </b-collapse>
                </li>

                <li :class="checkActive(purchases) ? 'active' : ''" v-if="showdeveloper">
                    <a v-b-toggle.purchase class="collapsed">
                        <svg class="svg-icon" id="p-dash5" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                            <line x1="1" y1="10" x2="23" y2="10"></line>
                        </svg>
                        <span class="ml-4">구매(기존)</span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="purchase" class="iq-submenu" accordion="my-accordion">
                        <li :class="checkActive('purchase.listpurchases') ? 'active' : ''">
                            <router-link :to="{name: 'purchase.listpurchases'}">
                                <i class="las la-minus"></i><span>구매내역</span>
                            </router-link>
                        </li>
                        <li :class="checkActive('purchase.addpurchase') ? 'active' : ''">
                            <router-link :to="{name: 'purchase.addpurchase'}">
                                <i class="las la-minus"></i><span>1688주문서생성</span>
                            </router-link>
                        </li>
                    </b-collapse>
                </li>

                <li :class="checkActive(purchases) ? 'active' : ''" v-if="showpurchase">
                    <a v-b-toggle.purchase1 class="collapsed">
                        <svg class="svg-icon" id="p-dash5" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                            <line x1="1" y1="10" x2="23" y2="10"></line>
                        </svg>
                        <span class="ml-4">구매대행</span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="purchase1" class="iq-submenu" accordion="my-accordion">
                        <li :class="checkActive('purchase.reqpurchase') ? 'active' : ''">
                            <router-link :to="{name: 'purchase.reqpurchase'}">
                                <i class="las la-minus"></i><span>구매대행요청</span>
                            </router-link>
                        </li>
                        <li :class="checkActive('purchase.listpurchases') ? 'active' : ''">
                            <router-link :to="{name: 'purchase.listreqpurchases'}">
                                <i class="las la-minus"></i><span>구매내역</span>
                            </router-link>
                        </li>
                    </b-collapse>
                </li>

                <li :class="checkActive(logistics) ? 'active' : ''" v-if="showimport">
                    <a v-b-toggle.logistics class="collapsed">
                        <svg class="svg-icon" id="p-dash14" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect>
                        </svg>
                        <span class="ml-4">해외물류</span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="logistics" class="iq-submenu" accordion="my-accordion">
                        <li :class="checkActive('logistics.logis') ? 'active' : ''">
                                <router-link :to="{name: 'logistics.logis'}">
                                    <i class="las la-minus"></i><span>물류재고</span>
                                </router-link>
                        </li>
                        <li :class="checkActive('logistics.logisinvoice') ? 'active' : ''">
                                <router-link :to="{name: 'logistics.logisinvoice'}">
                                    <i class="las la-minus"></i><span>출고내역(Invoice)</span>
                                </router-link>
                        </li>
                    </b-collapse>
                </li>


                <li :class="checkActive(sale) ? 'active' : ''" v-if="showstock">
                    <a v-b-toggle.sale class="collapsed">
                        <svg class="svg-icon" id="p-dash4" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                        </svg>
                        <span class="ml-4">재고/배치</span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="sale" class="iq-submenu" accordion="my-accordion">
                            <li :class="checkActive('sale.addsale') ? 'active' : ''">
                                    <router-link :to="{name: 'sale.addsale'}">
                                        <i class="las la-minus"></i><span>등록/변경/발주배치</span>
                                    </router-link>
                            </li>
                            <li :class="checkActive('sale.listsale') ? 'active' : ''">
                                    <router-link :to="{name: 'sale.listsale'}">
                                        <i class="las la-minus"></i><span>재고리스트(국내)</span>
                                    </router-link>
                            </li>
                            <li :class="checkActive('sale.totallistsale') ? 'active' : ''">
                                    <router-link :to="{name: 'sale.totallistsale'}">
                                        <i class="las la-minus"></i><span>재고리스트(전체)</span>
                                    </router-link>
                            </li>

                    </b-collapse>
                </li>
                <li :class="checkActive(exp) ? 'active' : ''" v-if="showexport">
                    <a v-b-toggle.exp class="collapsed" >
                        <svg class="svg-icon" id="p-dash19" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                        </svg>
                        <span class="ml-4">출고</span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="exp" class="iq-submenu" accordion="my-accordion">
                            <li :class="checkActive('export.addexport') ? 'active' : ''">
                                <router-link :to="{name: 'export.addexport'}">
                                    <i class="las la-minus"></i><span>출고준비</span>
                                </router-link>
                            </li>
                            <li :class="checkActive('export.exportlist') ? 'active' : ''">
                                <router-link :to="{name: 'export.exportlist'}">
                                    <i class="las la-minus"></i><span>출고리스트</span>
                                </router-link>
                            </li>

                    </b-collapse>
                </li>
                <li :class="checkActive(returns) ? 'active' : ''" v-if="showreturn">
                    <a v-b-toggle.return class="collapsed">
                        <svg class="svg-icon" id="p-dash6" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="4 14 10 14 10 20"></polyline><polyline points="20 10 14 10 14 4"></polyline><line x1="14" y1="10" x2="21" y2="3"></line><line x1="3" y1="21" x2="10" y2="14"></line>
                        </svg>
                        <span class="ml-4">회송</span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="return" class="iq-submenu" accordion="my-accordion">
                            <li :class="checkActive('return.listreturns') ? 'active' : ''">
                                    <router-link :to="{name: 'return.listreturns'}">
                                        <i class="las la-minus"></i><span>반품리스트</span>
                                    </router-link>
                            </li>
                            <li :class="checkActive('return.addreturn') ? 'active' : ''">
                                    <router-link :to="{name: 'return.addreturn'}">
                                        <i class="las la-minus"></i><span>반품등록</span>
                                    </router-link>
                            </li>
                    </b-collapse>
                </li>
                <li :class="checkActive(salesstatus) ? 'active' : ''" v-if="showsales">
                    <a v-b-toggle.salesstatus class="collapsed" >
                        <svg class="svg-icon" id="p-dash13" width="20" height="20"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                        </svg>
                        <span class="ml-4">판매/분석</span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="salesstatus" class="iq-submenu" accordion="my-accordion">

                            <li :class="checkActive('salesstatus.salesstatus') ? 'active' : ''">
                                    <router-link :to="{name: 'salesstatus.salesstatus'}">
                                        <i class="las la-minus"></i><span>판매현황</span>
                                    </router-link>
                            </li>
                            <li :class="checkActive('salesstatus.ai') ? 'active' : ''">
                                    <router-link :to="{name: 'salesstatus.ai'}">
                                        <i class="las la-minus"></i><span>AI발주수요예측

                                        </span>
                                    </router-link>
                            </li>
                            <li :class="checkActive('product.category') ? 'active' : ''">
                                <router-link :to="{name: 'product.category'}">
                                    <i class="las la-minus"></i><span>카테고리현황</span>
                                </router-link>
                            </li>
                            <li :class="checkActive('analysis.trackingsales') ? 'active' : ''">
                                    <router-link :to="{name: 'analysis.trackingsales'}">
                                        <i class="las la-minus"></i><span>경쟁사 판매추적
                                        </span>
                                    </router-link>
                            </li>
                            <li :class="checkActive('analysis.ranking') ? 'active' : ''">
                                    <router-link :to="{name: 'analysis.ranking'}">
                                        <i class="las la-minus"></i><span>쿠팡 랭킹추적
                                        </span>
                                    </router-link>
                            </li>
                            <li :class="checkActive('analysis.keyword') ? 'active' : ''">
                                    <router-link :to="{name: 'analysis.keyword'}">
                                        <i class="las la-minus"></i><span>키워드분석
                                        </span>
                                    </router-link>
                            </li>
                            <li :class="checkActive('analysis.sourcing') ? 'active' : ''">
                                    <router-link :to="{name: 'analysis.sourcing'}">
                                        <i class="las la-minus"></i><span>상품소싱 (N쇼핑해외)
                                        </span>
                                    </router-link>
                            </li>
                            <li :class="checkActive('analysis.keywordoptimize') ? 'active' : ''">
                                    <router-link :to="{name: 'analysis.keywordoptimize'}">
                                        <i class="las la-minus"></i><span>상품명메이커
                                            <span class="badge bg-success">
                                                new
                                            </span>
                                        </span>
                                    </router-link>
                            </li>
                            <!-- <li :class="checkActive('analysis.adkeyword') ? 'active' : ''">
                                    <router-link :to="{name: 'analysis.adkeyword'}">
                                        <i class="las la-minus"></i><span>수동키워드광고
                                            <span class="badge bg-success">
                                                new
                                            </span>
                                        </span>
                                    </router-link>
                            </li> -->
                    </b-collapse>
                </li>

                <li :class="checkActive(coubnam) ? 'active' : ''" v-if="showMin">
                    <a v-b-toggle.coubnam class="collapsed" >
                        <svg class="svg-icon" id="p-dash13" width="20" height="20"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                        </svg>
                        <span class="ml-4">데이터브랜딩</span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="coubnam" class="iq-submenu" accordion="my-accordion">
                            <li :class="checkActive('product.category_min') ? 'active' : ''">
                                <router-link :to="{name: 'product.category_min'}">
                                    <i class="las la-minus"></i><span>카테고리 분석
                                    </span>
                                </router-link>
                            </li>
                            <!-- <li :class="checkActive('analysis.adkeyword') ? 'active' : ''">
                                    <router-link :to="{name: 'analysis.adkeyword'}">
                                        <i class="las la-minus"></i><span>수동키워드광고
                                            <span class="badge bg-success">
                                                new
                                            </span>
                                        </span>
                                    </router-link>
                            </li> -->
                    </b-collapse>
                </li>

                <li :class="checkActive(Ad) ? 'active' : ''" v-if="showAd || showNomadAdManage">
                    <a v-b-toggle.ad class="collapsed" >
                        <svg class="svg-icon" id="p-dash13" width="20" height="20"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                        </svg>
                        <span class="ml-4">광고관리</span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="ad" class="iq-submenu" accordion="my-accordion">
                            <li :class="checkActive('Ad.adkeyword') ? 'active' : ''">
                                <router-link :to="{name: 'Ad.adkeyword'}">
                                    <i class="las la-minus"></i><span>수동키워드광고
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li>
                            <li :class="checkActive('Ad.adSourcing') ? 'active' : ''">
                                <router-link :to="{name: 'Ad.adSourcing'}">
                                    <i class="las la-minus"></i><span>광고관리(소싱검증)
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li>
                            <!-- <li :class="checkActive('Ad.adMarketing') ? 'active' : ''">
                                <router-link :to="{name: 'Ad.adMarketing'}">
                                    <i class="las la-minus"></i><span>광고관리(수동)
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li> -->
                    </b-collapse>
                </li>

                <li :class="checkActive(growth) ? 'active' : ''" v-if="showgrowth || showSCM">
                    <a v-b-toggle.growth class="collapsed" >
                        <svg class="svg-icon" id="p-dash13" width="20" height="20"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                        </svg>
                        <span class="ml-4">로켓그로스
                        </span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="growth" class="iq-submenu" accordion="my-accordion">

                            <li :class="checkActive('growth.growthdb') ? 'active' : ''">
                                <router-link :to="{name: 'growth.growthdb'}">
                                    <i class="las la-minus"></i><span>상품관리
                                    </span>
                                </router-link>
                            </li>
                            
                            <li :class="checkActive('nomad.nomadreqpo') ? 'active' : ''" v-if="showgrowth">
                                <router-link :to="{name: 'nomad.nomadreqpo'}">
                                    <i class="las la-minus"></i><span>구매 및 직납요청
                                    </span>
                                </router-link>
                            </li>
                            <li :class="checkActive('purchase.listpurchases') ? 'active' : ''" v-if="showgrowth">
                                <router-link :to="{name: 'purchase.listreqpurchases'}">
                                    <i class="las la-minus"></i><span>구매내역</span>
                                </router-link>
                            </li>
                            <!-- <li :class="checkActive('growth.samplePo') ? 'active' : ''" v-if="showgrowth">
                                <router-link :to="{name: 'growth.samplePo'}">
                                    <i class="las la-minus"></i><span>샘플검증요청
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li> -->
                            <!-- <li :class="checkActive('growth.growthsales') ? 'active' : ''" v-if="showsales || showlite || showSCM">
                                <router-link :to="{name: 'growth.growthsales'}">
                                    <i class="las la-minus"></i><span>판매현황
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li> -->
                            <li :class="checkActive('growth.growthscm') ? 'active' : ''" v-if="showsales || showlite || showSCM">
                                <router-link :to="{name: 'growth.growthscm'}">
                                    <i class="las la-minus"></i><span>공급관리(SCM)
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li>
                    </b-collapse>
                </li>
                <li :class="checkActive('nomad.keywordCollect') ? 'active' : ''" v-if="showKeywordCollect || shownomad">
                    <router-link :to="{name: 'nomad.nomadKeywordCollect'}" class="svg-icon">
                        <svg  class="svg-icon" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line>
                        </svg>
                        <span class="ml-4">키워드수집기
                            <span class="badge bg-success">
                                new
                            </span>
                        </span>
                    </router-link>
                </li>


                <li :class="checkActive(nomad) ? 'active' : ''" v-if="shownomadv2 || showNomadMenu || showNomadKeyword || showNomadCategory || showNomadAdKeyword">
                    <a v-b-toggle.nomad class="collapsed" >
                        <svg class="svg-icon" id="p-dash13" width="20" height="20"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                        </svg>
                        <span class="ml-4">노마드클럽
                        </span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="nomad" class="iq-submenu" accordion="my-accordion">
                            <li :class="checkActive('nomad.sourcingmasterTS') ? 'active' : ''" v-if="shownomadv2 || showNomadMenu">
                                <router-link :to="{name: 'nomad.sourcingmasterTS'}">
                                    <i class="las la-minus"></i><span>TrendSetter소싱
                                    </span>
                                </router-link>
                            </li>
                            <li :class="checkActive('nomad.sourcingmasterTF') ? 'active' : ''" v-if="shownomadv2 || showNomadMenu">
                                <router-link :to="{name: 'nomad.sourcingmasterTF'}">
                                    <i class="las la-minus"></i><span>TrendFollower소싱
                                    </span>
                                </router-link>
                            </li>
                            <li :class="checkActive('nomad.sourcingmasterTF2') ? 'active' : ''" v-if="shownomadv3">
                                <router-link :to="{name: 'nomad.sourcingmasterTF2'}">
                                    <i class="las la-minus"></i><span>TrendFollower소싱2
                                        
                                    </span>
                                </router-link>
                            </li>
                            <li :class="checkActive('nomad.saveitemM') ? 'active' : ''" v-if="shownomadv2 || showNomadMenu">
                                <router-link :to="{name: 'nomad.saveitemM'}">
                                    <i class="las la-minus"></i><span>TS/TF장바구니
                                        
                                    </span>
                                </router-link>
                            </li>
                            <li :class="checkActive('nomad.keywordAnalysis') ? 'active' : ''" v-if="shownomadv2 || showNomadKeyword">
                                <router-link :to="{name: 'nomad.nomadKeywordAnalysis'}">
                                    <i class="las la-minus"></i><span>키워드그래프
                                    </span>
                                </router-link>
                            </li>
                            <li :class="checkActive('nomad.nomadCategoryAnalysis') ? 'active' : ''" v-if="shownomadv2 || showNomadCategory">
                                <router-link :to="{name: 'nomad.nomadCategoryAnalysis'}">
                                    <i class="las la-minus"></i><span>카테고리최적화
                                    </span>
                                </router-link>
                            </li>
                            <li :class="checkActive('nomad.nomadtraffic') ? 'active' : ''" v-if="showTraffic">
                                <router-link :to="{name: 'nomad.nomadtraffic'}">
                                    <i class="las la-minus"></i><span>트래픽
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li>
                            <!-- <li :class="checkActive('analysis.adkeyword') ? 'active' : ''" v-if="showManualKeywordAd">
                                <router-link :to="{name: 'analysis.adkeyword'}" class="svg-icon">
                                    <i class="las la-minus"></i><span>수동광고키워드
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li> -->
                            <!-- <li :class="checkActive('nomad.sourcingVerify') ? 'active' : ''" v-if="shownomadv3">
                                <router-link :to="{name: 'nomad.sourcingVerify'}">
                                    <i class="las la-minus"></i><span>AI 소싱진단
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li> -->

                            <!-- <li :class="checkActive('nomad.sourcingmasterv2') ? 'active' : ''" v-if="shownomadv2 || showNomadMenu">
                                <router-link :to="{name: 'nomad.sourcingmasterv2'}">
                                    <i class="las la-minus"></i><span>AI소싱
                                    </span>
                                </router-link>
                            </li>
                            <li :class="checkActive('nomad.saveitem') ? 'active' : ''" v-if="shownomadv2 || showNomadMenu">
                                <router-link :to="{name: 'nomad.saveitem'}">
                                    <i class="las la-minus"></i><span>AI장바구니
                                    </span>
                                </router-link>
                            </li> -->

                            <li :class="checkActive('nomad.userlist') ? 'active' : ''" v-if="shownomadv2">
                                <router-link :to="{name: 'nomad.userlist'}">
                                    <i class="las la-minus"></i><span>외주 권한관리

                                    </span>
                                </router-link>
                            </li>
                            <!-- <li :class="checkActive('nomad.nomadreqpo') ? 'active' : ''">
                                <router-link :to="{name: 'nomad.nomadreqpo'}">
                                    <i class="las la-minus"></i><span>구매관리
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li> -->
                            <!-- <li :class="checkActive('growth.growthsales') ? 'active' : ''">
                                <router-link :to="{name: 'growth.growthsales'}">
                                    <i class="las la-minus"></i><span>판매현황
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li>
                            <li :class="checkActive('growth.growthscm') ? 'active' : ''">
                                <router-link :to="{name: 'growth.growthscm'}">
                                    <i class="las la-minus"></i><span>SCM관리
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li> -->
                    </b-collapse>
                </li>

                <li :class="checkActive(AI) ? 'active' : ''" v-if="showdeveloper">
                    <a v-b-toggle.AI class="collapsed" >
                        <svg class="svg-icon" id="p-dash13" width="20" height="20"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                        </svg>
                        <span class="ml-4">쿠플러스AI
                        </span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="AI" class="iq-submenu" accordion="my-accordion">
                            <li :class="checkActive('AI.CleanUp') ? 'active' : ''" v-if="showdeveloper">
                                <router-link :to="{name: 'AI.CleanUp'}">
                                    <i class="las la-minus"></i><span>Clean up
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li>
                    </b-collapse>
                    <b-collapse tag="ul" id="AI" class="iq-submenu" accordion="my-accordion">
                            <li :class="checkActive('AI.AIimage') ? 'active' : ''" v-if="showdeveloper">
                                <router-link :to="{name: 'AI.AIimage'}">
                                    <i class="las la-minus"></i><span>AI이미지에디터
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li>
                    </b-collapse>
                    <b-collapse tag="ul" id="AI" class="iq-submenu" accordion="my-accordion">
                            <li :class="checkActive('AI.AIpage') ? 'active' : ''" v-if="showdeveloper">
                                <router-link :to="{name: 'AI.AIpage'}">
                                    <i class="las la-minus"></i><span>AI상세페이지
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li>
                            <li :class="checkActive('AI.AIpageV2') ? 'active' : ''" v-if="showdeveloper">
                                <router-link :to="{name: 'AI.AIpageV2'}">
                                    <i class="las la-minus"></i><span>AI상세페이지V2
                                        <span class="badge bg-success">
                                            new
                                        </span>
                                    </span>
                                </router-link>
                            </li>
                    </b-collapse>
                </li>

                    <li :class="checkActive('product.category') ? 'active' : ''" v-if="showlite">
                        <router-link :to="{name: 'product.category'}" class="svg-icon">
                            <svg  class="svg-icon" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line>
                            </svg>
                            <span class="ml-4">신규로켓등록현황</span>
                        </router-link>
                    </li>
                    <li :class="checkActive('analysis.trackingsales') ? 'active' : ''" v-if="showlite">
                        <router-link :to="{name: 'analysis.trackingsales'}" class="svg-icon">
                            <svg  class="svg-icon" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line>
                            </svg>
                            <span class="ml-4">경쟁사 판매추적</span>
                        </router-link>
                    </li>
                    <li :class="checkActive('analysis.ranking') ? 'active' : ''" v-if="showlite || showCollectRank">
                        <router-link :to="{name: 'analysis.ranking'}" class="svg-icon">
                            <svg  class="svg-icon" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line>
                            </svg>
                            <span class="ml-4">쿠팡 랭킹추적</span>
                        </router-link>
                    </li>
                    <li :class="checkActive('analysis.keyword') ? 'active' : ''" v-if="showlite || showKeywordAnalysis">
                        <router-link :to="{name: 'analysis.keyword'}" class="svg-icon">
                            <svg  class="svg-icon" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line>
                            </svg>
                            <span class="ml-4">키워드분석</span>
                        </router-link>
                    </li>
                    <li :class="checkActive('analysis.keywordoptimize') ? 'active' : ''" v-if="showlite || makeProductName">
                        <router-link :to="{name: 'analysis.keywordoptimize'}" class="svg-icon">
                            <svg  class="svg-icon" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line>
                            </svg>
                            <span class="ml-4">상품명메이커</span>
                        </router-link>
                   </li>

                <li :class="checkActive(people) ? 'active' : ''" v-if="showdeveloper">
                    <a v-b-toggle.people class="collapsed" >
                        <svg class="svg-icon" id="p-dash8" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                        </svg>
                        <span class="ml-4">People</span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="people" class="iq-submenu" accordion="my-accordion">
                            <!-- <li :class="checkActive('people.customers') ? 'active' : ''">
                                    <router-link :to="{name: 'people.customers'}">
                                        <i class="las la-minus"></i><span>Customers</span>
                                    </router-link>
                            </li> -->
                            <!-- <li :class="checkActive('people.addcustomers') ? 'active' : ''">
                                    <router-link :to="{name: 'people.addcustomers'}">
                                        <i class="las la-minus"></i><span>Add Customers</span>
                                    </router-link>
                            </li> -->
                            <li :class="checkActive('people.users') ? 'active' : ''">
                                    <router-link :to="{name: 'people.users'}">
                                        <i class="las la-minus"></i><span>Users</span>
                                    </router-link>
                            </li>
                            <li :class="checkActive('people.addusers') ? 'active' : ''">
                                    <router-link :to="{name: 'people.addusers'}">
                                        <i class="las la-minus"></i><span>Add Users</span>
                                    </router-link>
                            </li>
                            <!-- <li :class="checkActive('people.suppliers') ? 'active' : ''">
                                    <router-link :to="{name: 'people.suppliers'}">
                                        <i class="las la-minus"></i><span>Suppliers</span>
                                    </router-link>
                            </li>
                            <li :class="checkActive('people.addsuppliers') ? 'active' : ''">
                                    <router-link :to="{name: 'people.addsuppliers'}">
                                        <i class="las la-minus"></i><span>Add Suppliers</span>
                                    </router-link>
                            </li> -->
                    </b-collapse>
                </li>
                <li :class="checkActive('layout.report') ? 'active' : ''" v-if="showdeveloper">
                    <router-link :to="{name: 'layout.report'}">
                        <svg class="svg-icon" id="p-dash7" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline>
                        </svg>
                        <span class="ml-4">Reports</span>
                    </router-link>
                    <ul id="reports" class="iq-submenu" >
                    </ul>
                </li>
                <li :class="checkActive(pages.concat(user,ui,auth,pricing,error)) ? 'active' : ''" v-if="showdeveloper">
                    <a v-b-toggle.otherpage class="collapsed" >
                        <svg class="svg-icon" id="p-dash9" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><rect x="7" y="7" width="3" height="9"></rect><rect x="14" y="7" width="3" height="5"></rect>
                        </svg>
                        <span class="ml-4">로켓그로스 전용</span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="otherpage" class="iq-submenu " accordion="my-accordion">
                            <li :class="checkActive(table) ? 'active' : ''" v-if="showdeveloper">
                                <a v-b-toggle.table class="collapsed">
                                    <svg class="svg-icon" id="p-dash14" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect>
                                    </svg>
                                    <span class="ml-4">로켓그로스</span>
                                    <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                    </svg>
                                </a>
                                <b-collapse tag="ul" id="table" class="iq-submenu" accordion="child-accordion">
                                        <li :class="checkActive('table.data-table') ? 'active' : ''">
                                            <router-link :to="{name: 'table.data-table'}" >
                                                <i class="las la-edit"></i><span>상품기획</span>
                                            </router-link>
                                        </li>
                                </b-collapse>
                            </li>
                            <li :class="checkActive(user) ? 'active' : ''" v-if="showdeveloper">
                                <a v-b-toggle.user class="collapsed">
                                    <svg class="svg-icon" id="p-dash10" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline>
                                    </svg>
                                    <span class="ml-4">User Details</span>
                                    <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                    </svg>
                                </a>
                                <b-collapse tag="ul" id="user" class="iq-submenu" accordion="child-accordion">
                                        <li :class="checkActive('app.user-profile') ? 'active' : ''" v-if="showdeveloper">
                                            <router-link :to="{name: 'app.user-profile'}">
                                                <i class="las la-minus"></i><span>User Profile</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('app.user-add') ? 'active' : ''" v-if="showdeveloper">
                                            <router-link :to="{name: 'app.user-add'}">
                                                <i class="las la-minus"></i><span>User Add</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('app.user-list') ? 'active' : ''">
                                            <router-link :to="{name: 'app.user-list'}">
                                                <i class="las la-minus"></i><span>User List</span>
                                            </router-link>
                                        </li>
                                </b-collapse>
                            </li>
                            <li :class="checkActive(ui) ? 'active' : ''" v-if="showdeveloper">
                                <a v-b-toggle.ui class="collapsed">
                                   <svg class="svg-icon" id="p-dash11" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                    </svg>
                                    <span class="ml-4">UI Elements</span>
                                    <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                    </svg>
                                </a>
                                <b-collapse tag="ul" id="ui" class="iq-submenu" accordion="child-accordion">
                                        <li :class="checkActive('Ui.avatars') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.avatars'}">
                                                <i class="las la-minus"></i><span>Avatars</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.alerts') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.alerts'}">
                                                <i class="las la-minus"></i><span>Alerts</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.badges') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.badges'}">
                                                <i class="las la-minus"></i><span>Badges</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.breadcrumbs') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.breadcrumbs'}">
                                                <i class="las la-minus"></i><span>Breadcrumb</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.buttons') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.buttons'}">
                                                <i class="las la-minus"></i><span>Buttons</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.button-groups') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.button-groups'}">
                                                <i class="las la-minus"></i><span>Buttons Group</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.boxshadows') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.boxshadows'}">
                                                <i class="las la-minus"></i><span>Box Shadow</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.colors') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.colors'}">
                                                <i class="las la-minus"></i><span>Colors</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.cards') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.cards'}">
                                                <i class="las la-minus"></i><span>Cards</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.carousels') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.carousels'}">
                                                <i class="las la-minus"></i><span>Carousel</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.grids') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.grids'}">
                                                <i class="las la-minus"></i><span>Grid</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.helper-classes') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.helper-classes'}">
                                                <i class="las la-minus"></i><span>Helper classes</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.images') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.images'}">
                                                <i class="las la-minus"></i><span>Images</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.list-groups') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.list-groups'}">
                                                <i class="las la-minus"></i><span>list Group</span>
                                            </router-link>
                                        </li>
                                        <li  :class="checkActive('Ui.media-objects') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.media-objects'}">
                                                <i class="las la-minus"></i><span>Media</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.modals') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.modals'}">
                                                <i class="las la-minus"></i><span>Modal</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.notifications') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.notifications'}">
                                                <i class="las la-minus"></i><span>Notifications</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.paginations') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.paginations'}">
                                                <i class="las la-minus"></i><span>Pagination</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.popovers') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.popovers'}">
                                                <i class="las la-minus"></i><span>Popovers</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.progressbars') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.progressbars'}">
                                                <i class="las la-minus"></i><span>Progressbars</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.typographys') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.typographys'}">
                                                <i class="las la-minus"></i><span>Typography</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.tabs') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.tabs'}">
                                                <i class="las la-minus"></i><span>Tabs</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.tooltips') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.tooltips'}">
                                                <i class="las la-minus"></i><span>Tooltips</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('Ui.Embed-videos') ? 'active' : ''">
                                            <router-link :to="{name: 'Ui.Embed-videos'}">
                                                <i class="las la-minus"></i><span>Video</span>
                                            </router-link>
                                        </li>
                                </b-collapse>
                            </li>
                            <li :class="checkActive(auth) ? 'active' : ''" v-if="showdeveloper">
                                <a v-b-toggle.auth class="collapsed">
                                    <svg class="svg-icon" id="p-dash12" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline>
                                    </svg>
                                    <span class="ml-4">Authentication</span>
                                    <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                    </svg>
                                </a>
                                <b-collapse tag="ul" id="auth" class="iq-submenu" accordion="child-accordion">
                                        <li :class="checkActive('auth.login') ? 'active' : ''">
                                            <router-link :to="{name: 'auth.login'}">
                                                <i class="las la-minus"></i><span>Login</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('auth.register') ? 'active' : ''">
                                            <router-link :to="{name: 'auth.register'}">
                                                <i class="las la-minus"></i><span>Register</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('auth.recover-password') ? 'active' : ''">
                                            <router-link :to="{name: 'auth.recover-password'}">
                                                <i class="las la-minus"></i><span>Recover Password</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('auth.confirm-mail') ? 'active' : ''">
                                            <router-link :to="{name: 'auth.confirm-mail'}">
                                                <i class="las la-minus"></i><span>Confirm Mail</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('auth.lock-screen') ? 'active' : ''">
                                            <router-link :to="{name: 'auth.lock-screen'}">
                                                <i class="las la-minus"></i><span>Lock Screen</span>
                                            </router-link>
                                        </li>
                                </b-collapse>
                            </li>
                            <li :class="checkActive(form) ? 'active' : ''" v-if="showdeveloper">
                                <a v-b-toggle.form class="collapsed svg-icon">
                                    <svg class="svg-icon" id="p-dash13" width="20" height="20"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                                    </svg>
                                    <span class="ml-4">Forms</span>
                                    <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                    </svg>
                                </a>
                                <b-collapse tag="ul" id="form" class="iq-submenu" accordion="child-accordion">
                                    <li :class="checkActive('controls.form-layout') ? 'active' : ''">
                                        <router-link :to="{name: 'controls.form-layout'}">
                                            <i class="las la-minus"></i><span class="">Form Elements</span>
                                        </router-link>
                                    </li>
                                    <li :class="checkActive('controls.form-input') ? 'active' : ''">
                                        <router-link :to="{name: 'controls.form-input'}" class="svg-icon">
                                           <i class="las la-minus"></i><span class="">Form Input</span>
                                        </router-link>
                                    </li>
                                    <li :class="checkActive('controls.form-validation') ? 'active' : ''">
                                        <router-link :to="{name: 'controls.form-validation'}" class="svg-icon">
                                            <i class="las la-minus"></i><span class="">Form Validation</span>
                                        </router-link>
                                    </li>
                                    <li :class="checkActive('controls.form-switch') ? 'active' : ''">
                                        <router-link :to="{name: 'controls.form-switch'}" class="svg-icon">
                                            <i class="las la-minus"></i><span class="">Form Switch</span>
                                        </router-link>
                                    </li>
                                    <li :class="checkActive('controls.form-checkbox') ? 'active' : ''">
                                        <router-link :to="{name: 'controls.form-checkbox'}" class="svg-icon">
                                            <i class="las la-minus"></i><span class="">Form Checkbox</span>
                                        </router-link>
                                    </li>
                                    <li :class="checkActive('controls.form-radio') ? 'active' : ''">
                                        <router-link :to="{name: 'controls.form-radio'}" class="svg-icon">
                                            <i class="las la-minus"></i><span class="">Form Radio</span>
                                        </router-link>
                                    </li>
                                    <li :class="checkActive('controls.form-textarea') ? 'active' : ''">
                                        <router-link :to="{name: 'controls.form-textarea'}" class="svg-icon">
                                            <i class="las la-minus"></i><span class="">Form Textarea</span>
                                        </router-link>
                                    </li>
                                </b-collapse>
                            </li>
                            <li :class="checkActive(table) ? 'active' : ''" v-if="showdeveloper">
                                <a v-b-toggle.table class="collapsed">
                                    <svg class="svg-icon" id="p-dash14" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect>
                                    </svg>
                                    <span class="ml-4">Table</span>
                                    <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                    </svg>
                                </a>
                                <b-collapse tag="ul" id="table" class="iq-submenu" accordion="child-accordion">
                                        <li :class="checkActive('table.basic-table') ? 'active' : ''">
                                            <router-link :to="{name: 'table.basic-table'}" >
                                                <i class="las la-minus"></i><span>Basic Tables</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('table.data-table') ? 'active' : ''">
                                            <router-link :to="{name: 'table.data-table'}" >
                                                <i class="las la-minus"></i><span>Data Table</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('table.tree-table') ? 'active' : ''">
                                            <router-link :to="{name: 'table.tree-table'}" >
                                                <i class="las la-minus"></i><span>Table Tree</span>
                                            </router-link>
                                        </li>
                                </b-collapse>
                            </li>
                            <li :class="checkActive(pricing) ? 'active' : ''" v-if="showdeveloper">
                                <a v-b-toggle.pricing class="collapsed" >
                                    <svg class="svg-icon" id="p-dash16" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                                    </svg>
                                    <span class="ml-4">Pricing</span>
                                    <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                    </svg>
                                </a>
                                <b-collapse tag="ul" id="pricing" class="iq-submenu" accordion="child-accordion">
                                        <li :class="checkActive('price.pay') ? 'active' : ''">
                                            <router-link :to="{name: 'price.pay'}" >
                                                <i class="las la-minus"></i><span>Pricing 1</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('price.pay2') ? 'active' : ''">
                                            <router-link :to="{name: 'price.pay2'}" >
                                                <i class="las la-minus"></i><span>Pricing 2</span>
                                            </router-link>
                                        </li>
                                </b-collapse>
                            </li>
                            <li :class="checkActive('pages.invoices') ? 'active' : ''" v-if="showdeveloper">
                                <router-link :to="{name: 'pages.invoices'}" class="svg-icon">
                                    <svg class="svg-icon" id="p-dash07" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline>
                                    </svg>
                                    <span class="ml-4">Invoice</span>
                                </router-link>
                            </li>
                            <li :class="checkActive(error) ? 'active' : ''" v-if="showdeveloper">
                                <a v-b-toggle.pages-error class="collapsed" >
                                    <svg class="svg-icon" id="p-dash17" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line>
                                    </svg>
                                    <span class="ml-4">Error</span>
                                    <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                    </svg>
                                </a>
                                <b-collapse tag="ul" id="pages-error" class="iq-submenu" accordion="child-accordion">
                                        <li :class="checkActive('error.404') ? 'active' : ''">
                                            <router-link :to="{name: 'error.404'}">
                                                <i class="las la-minus"></i><span>Error 404</span>
                                            </router-link>
                                        </li>
                                        <li :class="checkActive('error.500') ? 'active' : ''">
                                            <router-link :to="{name: 'error.500'}">
                                                <i class="las la-minus"></i><span>Error 500</span>
                                            </router-link>
                                        </li>
                                </b-collapse>
                            </li>
                            <li :class="checkActive('pages.blank-page') ? 'active' : ''" v-if="showdeveloper">
                                    <router-link :to="{name: 'pages.blank-page'}">
                                        <svg class="svg-icon" id="p-dash18" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline>
                                        </svg>
                                        <span class="ml-4">Blank Page</span>
                                    </router-link>
                            </li>
                            <li :class="checkActive('pages.maintenance') ? 'active' : ''" v-if="showdeveloper">
                                    <router-link :to="{name: 'pages.maintenance'}">
                                        <svg class="svg-icon" id="p-dash19" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
                                        </svg>
                                        <span class="ml-4">Maintenance</span>
                                    </router-link>
                            </li>
                    </b-collapse>
                </li>

                <li :class="checkActive(logis) ? 'active' : ''" v-if="showadmin">
                    <a v-b-toggle.logis class="collapsed" >
                        <svg class="svg-icon" id="p-dash13" width="20" height="20"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                        </svg>
                        <span class="ml-4">COUPLUS WEIHAI</span>
                        <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                        </svg>
                    </a>
                    <b-collapse tag="ul" id="logis" class="iq-submenu" accordion="my-accordion">
                        <li :class="checkActive(logis) ? 'active' : ''" v-if="showadmin">
                            <router-link :to="{name: 'logis.logisuserlist'}" >
                                <svg class="svg-icon" id="p-dash5" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                                    <line x1="1" y1="10" x2="23" y2="10"></line>
                                </svg>
                                <span class="ml-4">사용자내역</span>
                            </router-link>
                        </li>
                        <li :class="checkActive(logis) ? 'active' : ''" v-if="showadmin">
                            <router-link :to="{name: 'logis.logisreqpurchase'}" >
                                <svg class="svg-icon" id="p-dash5" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                                    <line x1="1" y1="10" x2="23" y2="10"></line>
                                </svg>
                                <span class="ml-4">商品代购(구매대행)</span>
                            </router-link>
                        </li>
                        <!-- <li :class="checkActive(logis) ? 'active' : ''" v-if="showadmin">
                            <router-link :to="{name: 'logis.logisaddpurchase'}" >
                                <svg class="svg-icon" id="p-dash5" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                                    <line x1="1" y1="10" x2="23" y2="10"></line>
                                </svg>
                                <span class="ml-4">采购清单(구매진행)</span>
                            </router-link>
                        </li> -->
                        <li :class="checkActive(logis) ? 'active' : ''" v-if="showadmin">
                            <router-link :to="{name: 'logis.logispurchaselist'}">
                                <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                </svg>
                                <span class="ml-4">订单列表(구매내역)</span>
                            </router-link>
                        </li>
                        <li :class="checkActive(logis) ? 'active' : ''" v-if="showadmin">
                            <router-link :to="{name: 'logis.logisimportbox'}" >
                                <svg class="svg-icon" id="p-dash13" width="20" height="20"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                                </svg>
                                <span class="ml-4">商品入库(입고)</span>
                            </router-link>
                        </li>
                        <li :class="checkActive(logis) ? 'active' : ''" v-if="showadmin">
                            <router-link :to="{name: 'logis.logisaddimport'}" >
                                <svg class="svg-icon" id="p-dash13" width="20" height="20"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                                </svg>
                                <span class="ml-4">商品入出库(입출고)</span>
                            </router-link>
                        </li>
                        <li :class="checkActive(logis) ? 'active' : ''" v-if="showadmin">
                            <router-link :to="{name: 'logis.logisimportlist'}" >
                                <svg class="svg-icon" id="p-dash19" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                                </svg>
                                <span class="ml-4">出库列表(출고내역)</span>
                            </router-link>
                        </li>
                        <li :class="checkActive(logis) ? 'active' : ''" v-if="showadmin">
                            <router-link :to="{name: 'logis.logisnotify'}" >
                                <svg class="svg-icon" id="p-dash19" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                                </svg>
                                <span class="ml-4">공지</span>
                            </router-link>
                        </li>
                        <li :class="checkActive(logis) ? 'active' : ''" v-if="showadmin">
                            <router-link :to="{name: 'logis.logisTotalList'}">
                                <svg class="svg-icon" id="p-dash3" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                                </svg>
                                <span class="ml-4">订单列表</span>
                            </router-link>
                        </li>
                        <!-- <li :class="checkActive(logis) ? 'active' : ''" v-if="showadmin">
                            <a v-b-toggle.logisexport class="collapsed" >
                                <svg class="svg-icon" id="p-dash16" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                                </svg>
                                <span class="ml-4">出库管理(출고)</span>
                                <svg class="svg-icon iq-arrow-right arrow-active" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <polyline points="10 15 15 20 20 15"></polyline><path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                </svg>
                            </a>
                            <b-collapse tag="ul" id="logisexport" class="iq-submenu" accordion="child-accordion">
                                    <li :class="checkActive(logis) ? 'active' : ''">
                                        <router-link :to="{name: 'logis.logisaddexport'}" >
                                            <i class="las la-minus"></i><span>商品出库(출고진행)</span>
                                        </router-link>
                                    </li>
                                    <li :class="checkActive(logis) ? 'active' : ''">
                                        <router-link :to="{name: 'logis.logisexportlist'}" >
                                            <i class="las la-minus"></i><span>出库列表(출고리스트)</span>
                                        </router-link>
                                    </li>
                            </b-collapse>
                        </li> -->

                    </b-collapse>
                </li>
            </ul>
        </nav>
        <!-- <div id="sidebar-bottom" class="position-relative sidebar-bottom">
            <div class="card border-none">
                <div class="card-body p-0">
                    <div class="sidebarbottom-content">
                        <div class="image"><img src="@/assets/images/layouts/side-bkg.png" class="img-fluid" alt="side-bkg"></div>
                        <h6 class="mt-4 px-4 body-title">Get More Feature by Upgrading</h6>
                        <button type="button" class="btn sidebar-bottom-btn mt-4">Go Premium</button>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="p-3"></div>
    </div>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {core} from '@/config/pluginInit'
export default {
  name:"SidebarStyle",
  data () {
    return{
      nomad:['nomad.sourcingmaster','nomad.sourcingmasterTS','nomad.sourcingmasterTF','nomad.nomadreqpo','nomad.sourcingmasterv2','nomad.saveitem','nomad.saveitemM','nomad.userlist','nomad.nomadKeywordAnalysis','nomad.nomadKeywordCollect','nomad.sourcingmasterTF2','nomad.sourcingVerify','nomad.nomadCategoryAnalysis','nomad.nomadtraffic'],
      AI:['AI.AIpage','AI.AIpageV2','AI.AIimage','AI.CleanUp'],
      Ad:['Ad.adkeyword','Ad.adSourcing','Ad.adMarketing'],
      product:['product.listproduct','product.addproduct','product.regproduct','product.category'],
      coubnam:['product.category_min'],
      growth:['growth.growthsales','growth.growthscm'],
      sale:['sale.listsale','sale.addsale','sale.totallistsale','sale.logis'],
      salesstatus:['salesstatus.salesstatus','analysis.keyword','salesstatus.ai','analysis.trackingsales','analysis.ranking','analysis.sourcing','analysis.keywordoptimize'],
      categories:[ 'category.listcategory','category.addcategory'],
      purchases:['purchase.listpurchases','purchase.listreqpurchases','purchase.addpurchase','purchase.reqpurchase'],
      logistics:['logistics.logis','logistics.logisinvoice'],
      returns:['return.listreturns','return.addreturn'],
      people:['people.customers','people.addcustomers','people.users','people.addusers','people.suppliers','people.addsuppliers'],
      exp:['export.exportlist','export.addexport'],
    //   imp:['import.importlist','import.addimport'],
      logis:['logis.logisreqpurchase','logis.logisuserlist','logis.logisimportlist','logis.logisimportbox','logis.logisaddimport','logis.logisexportlist','logis.logisaddexport','logis.logispurchaselist','logis.logisaddpurchase', 'logis.logisnotify','logis.logisTotalList'],
      user:['app.user-profile','app.user-add','app.user-list'],
      ui:['Ui.avatars','Ui.alerts','Ui.badges','Ui.breadcrumbs','Ui.buttons','Ui.button-groups','Ui.boxshadows','Ui.colors','Ui.cards','Ui.carousels','Ui.grids','Ui.helper-classes','Ui.images','Ui.list-groups','Ui.media-objects','Ui.modals','Ui.notifications'
      ,'Ui.paginations','Ui.popovers','Ui.progressbars','Ui.typographys','Ui.tabs','Ui.tooltips','Ui.Embed-videos'],
      auth:['auth.login','auth.register','auth.recover-password','auth.confirm-mail','auth.lock-screen'],
      pricing:['price.pay','price.pay2'],
      error:['error.404','error.500'],
      form:['controls.form-checkbox','controls.form-layout','controls.form-input','controls.form-radio','controls.form-switch','controls.form-textarea','controls.form-validation',],
      table:['table.basic-table','table.data-table','table.edit-table',],
      pages:[],
      showdashboard : this.$store.state.user.auth.showdashboard,
      showgrowth : this.$store.state.user.auth.showgrowth ? this.$store.state.user.auth.showgrowth : false,
      showproduct : this.$store.state.user.auth.showproduct,
      showpolist : this.$store.state.user.auth.showpolist,
      showstock : this.$store.state.user.auth.showstock,
      showpurchase : this.$store.state.user.auth.showpurchase,
      showreturn : this.$store.state.user.auth.showreturn,
      showsales : this.$store.state.user.addAccount ? true : this.$store.state.user.auth.showsales,
      showimport : this.$store.state.user.auth.showimport,
      showexport : this.$store.state.user.auth.showexport,
      showdeveloper : this.$store.state.user.auth.showdeveloper,
      showadmin : this.$store.state.user.auth.showadmin,
      shownomad : this.$store.state.user.auth.shownomad,
      shownomadv2 : this.$store.state.user.auth.shownomadv2,
      shownomadv3 : this.$store.state.user.auth.shownomadv3,
      showTraffic : this.$store.state.user.auth.showTraffic,
      showNomadKeyword : this.$store.state.user.auth.showNomadKeyword,
      showNomadCategory : this.$store.state.user.auth.showNomadCategory,
      showNomadAdKeyword : this.$store.state.user.auth.showNomadAdKeyword,
      showSCM : this.$store.state.user.auth.showSCM,
      showlite : this.$store.state.user.addAccount ? false : this.$store.state.user.auth.showlite,
      addAccount : this.$store.state.user.addAccount,
      showMin :this.$store.state.user.auth.showMin,
      showAd : this.$store.state.user.auth.showAd,
      showKeywordCollect : this.$store.state.user.auth.showKeywordCollect,
      showCollectRank : this.$store.state.user.auth.showCollectRank,


      showManualKeywordAd : this.$store.state.user.auth.showManualKeywordAd,
      showNomadMenu : this.$store.state.user.auth.showNomadMenu,
      showNomadAdManage : this.$store.state.user.auth.showNomadAdManage,
      showKeywordAnalysis : this.$store.state.user.auth.showKeywordAnalysis,

      makeProductName : this.$store.state.user.auth.makeProductName,
    //   uselogis : this.$store.state.user.auth.uselogis,
    }
  },
  mounted () {
    core.SmoothScrollbar()
    core.changesidebar()
  },
   destroyed () {
    core.SmoothScrollbar()
    core.changesidebar()
  },
  computed : {
        ...mapGetters({
            appName: 'appName',
            logo:'logo'
        })
    },
    methods: {
        checkActive(route) {
            //import, export logistics업무 관련 내용 추가

            if (this.$route.name == route) {
                return true;
            }
        }
}

}
</script>
